import React from "react";
import "./PrivacyPolicy.css"; // Ensure this file exists

const PrivacyPolicy = () => {
  return (
    <section className="privacy-policy">
      <div className="privacy-container">
        <h1>Privacy Policy</h1>

        <div className="policy-section">
          <h2>Introduction</h2>
          <p>
            This Privacy Policy outlines how we collect, use, disclose, retain, and safeguard your personal data when you interact with our website, services, or platforms. Our commitment to transparency and accountability ensures that your personal data is handled with the utmost care.
          </p>
          <p>
            This Privacy Policy applies globally to all users, irrespective of their location, and ensures compliance with applicable regional and international data protection regulations, including GDPR, CCPA, and other relevant laws. By accessing our website or using our services, you consent to the practices described herein.
          </p>
          <p>
            This Privacy Policy applies to all interactions you have with ITpay Private Limited, whether through our website, services, or digital platforms. It encompasses data collected online and offline, including information obtained from third-party integrations. It is important to note that this policy does not govern external websites linked from our site, and we encourage you to review the privacy policies of such external websites.
          </p>
        </div>

        <div className="policy-section">
          <h2>Information We Collect</h2>
          <ul>
            <li>Personal information such as your name, address, contact details (email and phone number), job title, and government-issued identification data, including PAN or Aadhaar, as required for compliance purposes.</li>
            <li>Behavioural data includes interaction patterns with our website and services, user preferences, interests, and feedback, which help us personalize your experience.</li>
            <li>Technical data includes information such as browser type, device details, IP address, and geolocation. We also use cookies and tracking identifiers to enhance session management and usability.</li>
            <li>Data that is no longer required for processing will be anonymized and used solely for research or statistical purposes.</li>
            <li>We may collect data from device sensors, such as accelerometer or gyroscope data, when users access our services through mobile applications.</li>
            <li>Any biometric data collected will be handled in strict compliance with applicable laws and will not be stored longer than necessary.</li>
            <li>We do not process sensitive personal data unless explicitly required and consented to, such as during KYC processes or regulatory compliance.</li>
          </ul>
        </div>

        <div className="policy-section">
          <h2>Methods of Data Collection</h2>
          <p>
            We collect data through direct interactions when you provide information via registration, surveys, or inquiries. Additionally, automated technologies like cookies, web beacons, and logs capture data during your interaction with our website. We may also collect data from third-party sources such as affiliates, partners, or publicly available records, ensuring compliance with applicable laws.
          </p>
        </div>

        <div className="policy-section">
          <h2>Use of Information</h2>
          <p>Your data is processed for a variety of purposes aimed at enhancing your experience and ensuring compliance with our obligations, including:</p>
          <ul>
            <li>Facilitating transactions, KYC processes, onboarding customers, and delivering personalized services.</li>
            <li>Meeting regulatory requirements, addressing legal inquiries, and detecting and preventing fraudulent activities.</li>
            <li>Sending service updates, promotional offers, and other notifications relevant to your interaction with us.</li>
            <li>Analyzing behavioral data to monitor user behavior, optimize performance, and improve our security measures.</li>
          </ul>
        </div>

        <div className="policy-section">
          <h2>Legal Basis for Processing</h2>
          <ul>
            <li><strong>Consent:</strong> We rely on your explicit consent for specific uses of your data.</li>
            <li><strong>Contractual Necessity:</strong> Data is processed to fulfil agreements or provide requested services.</li>
            <li><strong>Legal Compliance:</strong> We adhere to legal obligations requiring data processing.</li>
            <li><strong>Legitimate Interests:</strong> We use data to enhance services, ensure security, and prevent fraud while respecting your rights.</li>
          </ul>
        </div>

        <div className="policy-section">
          <h2>Data Sharing and Disclosure</h2>
          <p>Where data is transferred outside of the country or region, we ensure compliance with applicable laws through mechanisms such as Standard Contractual Clauses or Binding Corporate Rules.</p>
          <p>In the event of a merger, acquisition, or sale of assets, your data may be transferred to the acquiring entity, subject to the terms of this Privacy Policy.</p>
          <p>Data shared during legal audits or due diligence processes will be limited to the extent necessary and governed by confidentiality agreements.</p>
          <p>We may share data with third-party vendors for AI or machine learning applications to enhance services, ensuring that such data is anonymized where feasible.</p>
        </div>

        <div className="policy-section">
          <h2>Data Security</h2>
          <p>In the event of a data breach, we will notify affected users and relevant authorities within the timeframes mandated by applicable laws and will take immediate action to mitigate risks.</p>
          <p>Regular security audits, penetration testing, and compliance assessments are conducted to ensure robust data protection.</p>
          <p>All employees with access to personal data undergo periodic data protection and cybersecurity training to ensure adherence to the highest standards.</p>
        </div>

        <div className="policy-section">
          <h2>Data Retention</h2>
          <p>Retention periods are determined based on legal, operational, and business needs, and are reviewed periodically to ensure compliance with applicable laws.</p>
          <p>Transaction-related data may be retained for a period of 10 years to comply with financial regulations and audit requirements.</p>
          <p>Upon request or when no longer needed, your data is securely deleted following certified data destruction processes.</p>
          <p>We only collect the minimum data necessary to achieve the stated purposes and periodically review data collection practices to ensure compliance with this principle.</p>
        </div>

        <div className="policy-section">
          <h2>Your Rights</h2>
          <p>Under applicable laws, you have the following rights concerning your personal data:</p>
          <ul>
            <li>Right to Access: You may request access to the personal data we hold about you and obtain a copy for review.</li>
            <li>Right to Rectification: If your data is inaccurate or incomplete, you can request corrections to ensure accuracy.</li>
            <li>Right to Erasure: You have the right to request deletion of your data, subject to legal or operational limitations.</li>
            <li>Right to Restrict Processing: You may request a temporary suspension of data processing for specific purposes.</li>
            <li>Right to Data Portability: We provide your data in a structured, machine-readable format upon request, facilitating its transfer to other service providers.</li>
            <li>Right to Object: You can object to data processing, particularly for direct marketing or purposes based on legitimate interests.</li>
            <li>Automated Decision-Making: You may request human intervention in decisions made solely through automated processes.</li>
          </ul>
        </div>

        <div className="policy-section">
          <h2>Cookies and Tracking</h2>
          <p>Cookies and similar tracking technologies are used to enhance your experience. These small files help us understand user behaviour and preferences. Essential cookies necessary for functionality are always enabled to ensure seamless operation</p>
          <p>Some cookies used on our website may be set by third-party providers. We recommend reviewing their respective privacy policies for more information.</p>
          <p>Users can customize their cookie preferences dynamically through our cookie management tool, which provides options to enable or disable different categories of cookies such as functional, performance, and advertising cookies.</p>
        </div>

        <div className="policy-section">
          <h2>Third-Party Integrations</h2>
          <p>Our services may integrate with third-party platforms, including payment gateways, analytics tools, and advertising networks.</p>
          <p>We ensure that third-party service providers adhere to data protection standards, but we are not liable for their independent data handling practices beyond contractual obligations. These platforms are governed by their respective privacy policies, and we encourage you to review them for a comprehensive understanding of their practices.</p>
        </div>

        <div className="policy-section">
          <h2>Children’s Privacy</h2>
          <p>Our services are designed for individuals aged 18 and above. We do not knowingly collect data from minors without explicit parental consent. If we become aware of such data collection, it is promptly deleted.</p>
        </div>

        <div className="policy-section">
          <h2>Grievance Redressal</h2>
          <p>For concerns or complaints related to your data or this Privacy Policy, you may contact our Grievance Officer at:</p>
          <p>Email: <a href="mailto:support@itpay.in">support@itpay.in</a></p>
          <p>Address: AG-08 Tower A, Pioneer Urban Square, Sector 62, Gurgaon, 122102, India.</p>
          <p>We aim to address all grievances within 15 working days.</p>
        </div>

        <div className="policy-section">
          <h2>Updates to the Policy</h2>
          <p>This Privacy Policy is subject to periodic updates to reflect changes in practices, regulations, or services. We will notify users of significant updates via our website or direct communication.</p>
        </div>

        <div className="policy-section">
          <h2>Governing Law and Dispute Resolution</h2>
          <p>This policy is governed by the laws of India. Any disputes arising under this policy shall be resolved through arbitration in accordance with the Arbitration and Conciliation Act, 1996. The seat of arbitration will be Gurgaon, India.</p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
