// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
    padding: 40px 20px;
    color: #333;
  }
  
  .privacy-container {
    max-width: 900px;
    margin: 0 auto;
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 28px;
    color: #ff6200;
    text-align: center;
  }
  
  h2 {
    font-size: 22px;
    color: #343a40;
    margin-top: 20px;
  }
  
  .policy-section {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  ul li {
    margin: 10px 0;
  }
  
  .contact-info a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,wCAAwC;EAC1C;;EAEA;IACE,eAAe;IACf,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,6BAA6B;EAC/B;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".privacy-policy {\n    font-family: 'Arial', sans-serif;\n    background-color: #f8f9fa;\n    padding: 40px 20px;\n    color: #333;\n  }\n  \n  .privacy-container {\n    max-width: 900px;\n    margin: 0 auto;\n    background: white;\n    padding: 30px;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  h1 {\n    font-size: 28px;\n    color: #ff6200;\n    text-align: center;\n  }\n  \n  h2 {\n    font-size: 22px;\n    color: #343a40;\n    margin-top: 20px;\n  }\n  \n  .policy-section {\n    padding: 20px 0;\n    border-bottom: 1px solid #ddd;\n  }\n  \n  ul {\n    list-style-type: disc;\n    padding-left: 20px;\n  }\n  \n  ul li {\n    margin: 10px 0;\n  }\n  \n  .contact-info a {\n    color: #007bff;\n    text-decoration: none;\n  }\n  \n  .contact-info a:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
