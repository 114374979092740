import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import './App.css';
import Logo from './assets/Logo.jpeg'
import Hero1 from './assets/Hero1.jpeg'
import Hero4 from './assets/Hero4.jpeg'
import Hero3 from './assets/Hero3.jpeg'
import Hero5 from './assets/Hero5.jpeg'
import PaymentImage from './assets/PaymentImage.jpeg'
import Map from './assets/Map.jpeg'
import Icon1 from './assets/Icon1.jpeg'
import Icon2 from './assets/Icon2.jpeg'
import Icon3 from './assets/Icon3.jpeg'
import Google from './assets/google.png'
import FB from './assets/fb.png'
import Twitch from './assets/twitch.png'
import Pinterest from './assets/pinterest.png'
import Thanks from './components/Thanks'
import Webflow from './assets/webflow.png'
import Youtube from './assets/youtube.png'
import { ChevronDown, Maximize } from 'lucide-react';
import AboutUs from "./components/AboutUs";
import TermsConditions from "./components/TermsConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUs from "./components/ContactUs";
import { Twitter as TwitterIcon, Linkedin as LinkedinIcon, Youtube as YoutubeIcon, Instagram as InstagramIcon } from 'lucide-react';
import JoinUs from './components/JoinUs'
function App() {
  useEffect(() => {
    document.title = "ItPay";
    updateFavicon();
  }, [])
  const updateFavicon = () => {
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
      link.href = Logo;
    } else {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = Logo;
      document.head.appendChild(newLink);
    }
  };

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/JoinUs" element={<JoinUs />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/thanks" element={<Thanks />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

function Home() {
  return (
    <>
      <HeroSection />
      <LogoSection />
      <TrustedSection />
      <FeaturesSection />
      {/* <Footer /> */}
    </>
  );
}


function Header() {

  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleProductsMenu = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsProductsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <div className="header-container">
        <div style={{ marginLeft: 10 }} className="header-logo-container">
          <img src={Logo} alt="itPay Logo" className="header-logo" />
        </div>

        <div className="header-content">
          <nav className="header-nav">
            <a href="/">Home</a>
            <a href="/about">About Us</a>

            {/* Product Menu */}
            <div className="dropdown" ref={dropdownRef}>
              <a
                  href="#products"
                  className="dropdown-toggle"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleProductsMenu();
                  }}
              >
                Products
              </a>

              {isProductsOpen && (
                  <div className="dropdown-menu">
                    <div className="menu-container">

                      <div className="menu-column">
                        <h4 className="menu-title">Money Transfer</h4>
                        <span className="menu-item">Domestic Money Transfer</span>

                        <h4 className="menu-title">Aadhaar Banking</h4>
                        <span className="menu-item">Aadhaar Pay</span>
                        <span className="menu-item">Cash Withdrawal</span>
                        <span className="menu-item">Balance Enquiry</span>
                        <span className="menu-item">Mini Statement</span>

                        <h4 className="menu-title">Micro ATM</h4>
                        <span className="menu-item">Cash Withdrawal</span>
                        <span className="menu-item">Balance Enquiry</span>
                      </div>

                      <div className="menu-column">
                        <h4 className="menu-title">Utility Payments</h4>
                        <span className="menu-item">BBPS</span>
                        <span className="menu-item">Recharge</span>

                        <h4 className="menu-title">Travel Services</h4>
                        <span className="menu-item">Air Ticket</span>
                        <span className="menu-item">Railway Ticket</span>

                      </div>
                    </div>
                  </div>
              )}
            </div>

            <a href="/contact">Contact Us</a>
          </nav>

          <div className="header-buttons">
            <button className="signup-btn" onClick={() => window.location.href = '/JoinUs'}>Join
              Us
            </button>
            {/* <button className="talk-btn">Talk To Experts</button> */}
          </div>
          <div className="header-buttons">
            <button className="signup-btn" onClick={() => window.location.href = 'https://dev.itpay.in/'}>Login
            </button>
            {/* <button className="talk-btn">Talk To Experts</button> */}
          </div>
        </div>
      </div>
    </header>
  );
}


const HeroSection = () => {

  const targetSectionRef = useRef(null);
  const handleScroll = () => {
    if (targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({behavior: "smooth"});
    }
  };

  return (
    <>
      <section className="hero">
        <div className="hero-container">
          <div className="hero-content">
            <h1>
              SIMPLIFY <span className="highlight">PAYMENTS</span>, <br />
              EMPOWER YOUR <span className="highlight">BUSINESS</span>
            </h1>
            <p>
              Fast, secure, and seamless payment solutions for businesses of all sizes.
            </p>
            <button className="hero-button">Get Started Now</button>
          </div>

          <div className="hero-images">
            <div className="top-images">
              <img
                style={{ marginTop: 40, height: 150, width: 150 }}
                src={Hero5}
                alt="Card Payment"
                className="circle-image"
              />
              <img
                style={{ height: 220, width: 220 }}
                src={Hero4}
                alt="Finance Tools"
                className="circle-image"
              />
            </div>
            <div className="bottom-images">
              <img
                style={{ height: 220, width: 220 }}
                src={Hero3}
                alt="Mobile Payments"
                className="circle-image"
              />
              <img
                style={{ marginTop: 40, height: 150, width: 150 }}
                src={Hero1}
                alt="Terminal Payments"
                className="circle-image"
              />
            </div>
          </div>
        </div>

        <div className="scroll-indicator" onClick={handleScroll}>
          <ChevronDown size={80} color="#34A853" />
        </div>
      </section>


      <section ref={targetSectionRef} className="target-section">
        <AboutSection />
      </section>
    </>
  );
};

function AboutSection() {
  return (
    <section className="about">
      <div className="about-container">

        <div className="about-image">
          <img src={PaymentImage} alt="Payment Illustration" />
        </div>


        <div className="about-content">
          <h4 className="about-subtitle">WELCOME TO OUR SITE!</h4>
          <h2 className="about-title">
            We are the best company <br /> for your visit
          </h2>
          <p className="about-text">
            ITpay, we are dedicated to revolutionizing payment gateway
            solutions for a wide range of businesses. With a commitment to innovation and excellence,
            we strive to empower businesses with reliable payment solutions that enhance their
            operational efficiency and customer satisfaction. Partner with us to experience
            cutting-edge technology and unparalleled service in the financial services sector.
            Overcome Payment Challenges and Propel Your Business Forward with Our Secure and
            Streamlined Payment Gateway.
          </p>
        </div>
      </div>
    </section>
  );
}

function LogoSection() {
  const logos = [
    { src: Google, alt: "Google" },
    { src: FB, alt: "Facebook" },
    { src: Youtube, alt: "YouTube" },
    { src: Twitch, alt: "Twitch" },
    { src: Pinterest, alt: "Pinterest" },
    { src: Webflow, alt: "Webflow" },
  ];

  return (
    <section className="logo-section">
      <div className="logo-container">
        {logos.map((logo, index) => (
          <img key={index} src={logo.src} alt={logo.alt} className="logo" />
        ))}
      </div>
    </section>
  );
}
function TrustedSection() {
  const stats = [
    { value: "20+", label: "Years Experience" },
    { value: "100+", label: "Happy Customer" },
    { value: "15+", label: "Choice of Services" },
    { value: "10+", label: "Professional Guides" },
  ];

  return (
    <section className="trusted-section">
      <h2>
        Trusted by the people all <span>over the world</span>
      </h2>
      <div className="world-map">
        <img src={Map} alt="World Map" className="map-image" />
      </div>
      <div className="stats-container">
        {stats.map((stat, index) => (
          <div key={index} className="stat">
            <h3>{stat.value}</h3>
            <p>{stat.label}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

const FeaturesSection = () => {
  const features = [
    {
      id: 1,
      title: "Wide Range of Payment Options",
      icon: (
        <img
          src={Icon1}
          style={{ height: 50, width: 50, borderRadius: 100 }}
        >
        </img>
      ),
    },
    // {
    //   id: 2,
    //   title: "Multi-Currency Support",
    //   icon: (
    //     <img
    //       src={Icon3}
    //       style={{ height: 50, width: 50, borderRadius: 100 }}
    //     >
    //     </img>
    //   ),
    // },
    {
      id: 3,
      title: "Real-Time Reporting & Analytics",
      icon: (
        <img
          src={Icon2}
          style={{ height: 50, width: 50, borderRadius: 100 }}
        >
        </img>
      ),
    },
  ];

  return (
    <section className="features-section">
      <div className="features-header">
        <h2>
          A whole lot more than just sending <span className="highlight">money</span>. <br />
          We’re on steroids!
        </h2>
        <p>
          Discover payment gateway solutions designed to revolutionize your business
          operations with Indus-tech RMC Private Limited :)
        </p>
      </div>
      <div className="features-container">
        {features.map((feature) => (
          <div key={feature.id} className="feature-card">
            <div className="expand-icon">
              <Maximize size={20} color="currentColor" />
            </div>
            <div className="feature-icon">{feature.icon}</div>
            <h3>{feature.title}</h3>
          </div>
        ))}
      </div>
    </section>
  );
};


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-columns">
          <div className="footer-column">
            <h3>Product</h3>
            <ul>
              <li>Digital Invoice</li>
              <li>Insights</li>
              <li>Reimbursements</li>
              <li>Virtual Assistant</li>
              <li>Artificial Intelligence</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Company</h3>
            <ul>
              <li><Link to="/about" className="no-link-style">About Us</Link></li>
              <li>Newsletters</li>
              <li>Our Partners</li>
              <li>Career</li>
              <li><Link to="/contact" className="no-link-style">Contact Us</Link></li>
              <li><Link to="/terms" className="no-link-style">Terms & Conditions</Link></li>
              <li><Link to="/privacy" className="no-link-style">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Resources</h3>
            <ul>
              <li>Blog</li>
              <li>Pricing</li>
              <li>FAQ</li>
              <li>Events</li>
              <li>Ebook & Guide</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '10px 0', marginTop: '20px' }}>
        <div className="footer-logo-text" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={Logo} alt="Company Logo" style={{ height: 50, marginRight: 10 }} />
          <span>©Indus-Tech RMC Pvt. Ltd. All Rights Reserved.</span>
        </div>
        <div className="footer-social" style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
          <TwitterIcon size={25} />
          <LinkedinIcon size={25} />
          <YoutubeIcon size={25} />
          <InstagramIcon size={25} />
        </div>
      </div>
    </footer>
  );
};


export default App;
