// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 10%;
    background-color: #0c1c35; /* Dark theme background */
    color: white;
  }
  
  .about-content {
    flex: 1 1;
    max-width: 50%;
  }
  
  .tag {
    background-color: #1b3a5e;
    color: #00c48c;
    padding: 8px 14px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    margin-bottom: 12px;
  }
  
  .about-content h2 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .about-content p {
    font-size: 16px;
    font-style: italic;
    line-height: 1.8;
    color: #d1d9e6;
  }
  
  .about-image {
    flex: 1 1;
    display: flex;
    justify-content: center;
  }
  
  .about-image img {
    width: 90%;
    max-width: 500px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AboutUs.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,iBAAiB;IACjB,yBAAyB,EAAE,0BAA0B;IACrD,YAAY;EACd;;EAEA;IACE,SAAO;IACP,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,qBAAqB;IACrB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,SAAO;IACP,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,UAAU;IACV,gBAAgB;EAClB","sourcesContent":[".about-us {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 60px 10%;\n    background-color: #0c1c35; /* Dark theme background */\n    color: white;\n  }\n  \n  .about-content {\n    flex: 1;\n    max-width: 50%;\n  }\n  \n  .tag {\n    background-color: #1b3a5e;\n    color: #00c48c;\n    padding: 8px 14px;\n    border-radius: 20px;\n    font-size: 14px;\n    font-weight: bold;\n    display: inline-block;\n    margin-bottom: 12px;\n  }\n  \n  .about-content h2 {\n    font-size: 40px;\n    font-weight: bold;\n    margin-bottom: 15px;\n  }\n  \n  .about-content p {\n    font-size: 16px;\n    font-style: italic;\n    line-height: 1.8;\n    color: #d1d9e6;\n  }\n  \n  .about-image {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n  }\n  \n  .about-image img {\n    width: 90%;\n    max-width: 500px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
