import React, { useState } from "react";
import '@fortawesome/fontawesome-free/css/all.css';
import { useNavigate } from "react-router-dom";
import axios from "axios";

const JoinUs = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [role, setRole] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [formErrors, setFormErrors] = useState({}); // Store form errors
    const navigate = useNavigate();

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        if (!/^\d*$/.test(value)) {
            alert("Please enter numbers only in the phone input!");
            return;
        }
        if (value.length > 10) {
            alert("Phone number cannot be more than 10 digits!");
            return;
        }
        setPhone(value);
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const handleSignUpValidation = (e) => {
        e.preventDefault(); // Stop default form submission

        const errors = {};
        if (!name) errors.name = "Name is required!";
        if (!phone || phone.length !== 10) errors.phone = "Phone number must be exactly 10 digits.";
        if (!role) errors.role = "Please select a role.";
        //if (!email) errors.email = "Email is required.";
       // if (!password) errors.password = "Password is required.";
        //if (password !== confirmPassword) errors.confirmPassword = "Passwords do not match!";
        //if (!validatePassword(password)) errors.passwordError = "Password must be at least 8 characters, include an uppercase letter, a lowercase letter, a number, and a special character.";

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        // Clear form errors if no issues
        setFormErrors({});

        // After all fields are filled correctly, navigate to the /Thanks page
        navigate("/Thanks");
    };

    return (
        <>
            <style>
                {`
        .signup {
            top: 0;
            left: 0;
            width: 98vw;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
        }
        .signup-content {
            display: flex;
            background: white;
            border-radius: 10px;
            overflow: hidden;
            width: 70%;
            max-width: 900px;
        }
        .popup-left {
            flex: 1;
            background: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .popup-left img {
            max-width: 100%;
            height: auto;
        }
        .popup-right {
            flex: 1;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: linear-gradient(to bottom right, #ffffff, #f0f4ff);
        }
        .popup-right h2 {
            margin-bottom: 20px;
            font-size: 24px;
            color: #333;
        }
        .popup-right form {
            display: flex;
            flex-direction: column;
        }
        .popup-right input,
        .popup-right select {
            padding: 10px;
            margin-bottom: 15px;
            border: 1px solid #ddd;
            border-radius: 5px;
            font-size: 16px;
        }
        .popup-right .btn-submit {
            padding: 10px;
            background: #0056b3;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }
        .popup-right .btn-submit:hover {
            background: #003d80;
        }
        .popup-right p {
            margin-top: 20px;
            text-align: center;
        }
        .error {
            color: red;
            font-size: 14px;
            margin-top: 5px;
        }
        .register-icon {
            position: fixed;
            bottom: 20px;
            right: 20px;
            background-color: #007bff;
            color: white;
            padding: 10px;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
            z-index: 1001;
        }
                `}
            </style>

            <div style={{ marginTop: -100 }} className="signup">
                <div className="signup-content" onClick={(e) => e.stopPropagation()}>
                    <div className="popup-left">
                        <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp" alt="Background" />
                    </div>
                    <div className="popup-right">
                        <h2>Join Us</h2>
                        <form onSubmit={handleSignUpValidation}>
                            <input
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            {formErrors.name && <p className="error">{formErrors.name}</p>}

                            <input
                                type="tel"
                                placeholder="Phone"
                                value={phone}
                                onChange={handlePhoneChange}
                                required
                            />
                            {formErrors.phone && <p className="error">{formErrors.phone}</p>}

                            {/*<input*/}
                            {/*    type="email"*/}
                            {/*    placeholder="Email"*/}
                            {/*    value={email}*/}
                            {/*    onChange={(e) => setEmail(e.target.value)}*/}
                            {/*    required*/}
                            {/*/>*/}
                            {/*{formErrors.email && <p className="error">{formErrors.email}</p>}*/}

                            <div>
                                <select
                                    name="role"
                                    onChange={(e) => setRole(e.target.value)}
                                    style={{
                                        width: "100%",
                                        paddingLeft: 12,
                                        borderColor: "gray",
                                        borderRadius: 5,
                                        height: 35,
                                        backgroundColor: "white",
                                        fontSize: 13,
                                        color: 'grey'
                                    }}
                                    required
                                >
                                    <option value="" >Select a Role</option>
                                    <option value="DISTRIBUTOR">DISTRIBUTOR</option>
                                    <option value="MERCHANT">RETAILER</option>
                                    <option value="MASTER_DISTRIBUTOR">MASTER DISTRIBUTOR</option>
                                    <option value="SUPER_RETAILER">SUPER RETAILER</option>
                                </select>
                                {formErrors.role && <p className="error">{formErrors.role}</p>}
                            </div>

                            {/*<input*/}
                            {/*    type="password"*/}
                            {/*    placeholder="Password"*/}
                            {/*    value={password}*/}
                            {/*    onChange={(e) => setPassword(e.target.value)}*/}
                            {/*    required*/}
                            {/*/>*/}
                            {/*{formErrors.password && <p className="error">{formErrors.password}</p>}*/}

                            {/*<input*/}
                            {/*    type="password"*/}
                            {/*    placeholder="Confirm Password"*/}
                            {/*    value={confirmPassword}*/}
                            {/*    onChange={(e) => setConfirmPassword(e.target.value)}*/}
                            {/*    required*/}
                            {/*/>*/}
                            {/*{formErrors.confirmPassword && <p className="error">{formErrors.confirmPassword}</p>}*/}
                            {/*{formErrors.passwordError && <p className="error">{formErrors.passwordError}</p>}*/}

                            <button
                                type="submit"
                                style={{ marginTop: 10 }}
                                className="btn-submit"
                            >
                                Submit
                            </button>
                        </form>
                    </div>
                </div>

                <div className="register-icon">
                    <i className="fas fa-user-plus"></i>
                </div>
            </div>
        </>
    );
};

export default JoinUs;
