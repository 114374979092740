import React from "react";
import "./AboutUs.css"; // Separate CSS file for styling
import animationData from "../assets/Animation - 1738311932260.json"
import Lottie from 'react-lottie';

const AboutUs = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };

  return (
    <section className="about-us">
      <div className="about-content">
        <span className="tag">Who we are</span>
        <h2>About Us</h2>
        <p>
          India has emerged as a hub for fintech innovation, leading the way in digital payment solutions. 
          While rapid advancements in financial technology have positioned the country as a key player in 
          digital transactions, a significant portion of the population still lacks access to essential 
          banking services. 
          <br />
          <br />
          At <b>ITpay</b>, our mission goes beyond innovation—we are committed to bridging the financial 
          gap between urban and rural India by making banking and digital payments more accessible to everyone.
        </p>
      </div>
      <div className="about-image">
      <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    </section>
  );
};

export default AboutUs;
